import { uniq } from "lodash";
import { computed, readonly, ref, shallowRef } from "vue";
import { useI18n } from "vue-i18n";
import { getOrders } from "@/core/api/graphql/orders";
import { getOrdersForExport } from "@/core/api/graphql/orders/queries/getOrdersForExport/index";
import { DEFAULT_ORDERS_PER_PAGE, STATUS_ORDERS_FACET_NAME } from "@/core/constants";
import { SortDirection } from "@/core/enums";
import { Sort } from "@/core/types";
import { Logger, toEndDateFilterValue, toStartDateFilterValue } from "@/core/utilities";
import { useNotifications } from "@/shared/notification";
import { useUserOrdersFilter } from "./useUserOrdersFilter";
import type { OpusCustomerOrderType, FacetTermType, OpusCustomerOrderConnection } from "@/core/api/graphql/types";
import type { OpShortOrderType, OrdersFilterDataType } from "@/shared/account";
import type { MaybeRef, Ref } from "vue";

export interface IUseUserOrdersOptions {
  itemsPerPage?: MaybeRef<number>;
}

const facets = shallowRef<FacetTermType[] | undefined>();
const notifications = useNotifications();

export function useUserOrders(options: IUseUserOrdersOptions) {
  const { d, t } = useI18n();

  const itemsPerPage = ref(options.itemsPerPage ?? DEFAULT_ORDERS_PER_PAGE);

  const { appliedFilterData, setFacetsLocalization } = useUserOrdersFilter();

  const orders: Ref<OpusCustomerOrderType[]> = shallowRef<OpusCustomerOrderType[]>([]);
  const loading: Ref<boolean> = ref(false);
  const pages: Ref<number> = ref(0);
  const page: Ref<number> = ref(1);
  const exportPage: Ref<number> = ref(1);
  const total = ref(0);
  const keyword: Ref<string> = ref("");

  const sort: Ref<Sort> = ref(new Sort("createdDate", SortDirection.Descending));

  // OPUS
  const loadingMore: Ref<boolean> = ref(false);
  const exportingOrders: Ref<boolean> = ref(false);
  const facetOrdering = new Map<string, number>([
    ["completed", 1],
    ["confirmed", 2],
    ["payment required", 3],
    ["approval needed", 4],
    ["processing", 5],
    ["new", 6],
    ["cancelled", 7],
  ]);
  // !OPUS

  async function fetchOrders() {
    loading.value = true;
    orders.value = [];
    total.value = 0;
    pages.value = 1;

    const filterExpression = getFilterExpression(keyword.value, appliedFilterData.value);

    try {
      const response = await getOrders({
        sort: sort.value.toString(),
        first: itemsPerPage.value,
        after: String((page.value - 1) * itemsPerPage.value),
        filter: filterExpression,
        facet: STATUS_ORDERS_FACET_NAME,
        // OPUS
        isMainOrder: true,
        // !OPUS
      });
      orders.value = response.items ?? [];
      pages.value = Math.ceil((response.totalCount ?? 0) / itemsPerPage.value);
      facets.value = response.term_facets?.find((item) => item.name === STATUS_ORDERS_FACET_NAME)?.terms;
      setFacetsLocalization(facets.value);
    } catch (e) {
      Logger.error(`${useUserOrders.name}.${fetchOrders.name}`, e);
      throw e;
    } finally {
      loading.value = false;
    }
  }

  async function fetchMoreOrders() {
    loadingMore.value = true;

    const filterExpression = getFilterExpression(keyword.value, appliedFilterData.value);

    try {
      const { items = [], totalCount = 0 } = await getOrders({
        sort: sort.value.toString(),
        first: itemsPerPage.value,
        after: String((page.value - 1) * itemsPerPage.value),
        filter: filterExpression,
        isMainOrder: true,
      });

      orders.value = orders.value.concat(items);
      total.value = totalCount;
      pages.value = Math.ceil(total.value / (itemsPerPage.value || DEFAULT_ORDERS_PER_PAGE));
    } catch (e) {
      Logger.error(`useUserOrders.${fetchMoreOrders.name}`, e);
      throw e;
    } finally {
      loadingMore.value = false;
    }
  }

  //OPUS
  // eslint-disable-next-line sonarjs/cognitive-complexity
  function prepareOrdersForExport(ordersForMutation: OpusCustomerOrderType[]) {
    const _ordersForExport: OpShortOrderType[] = [];
    ordersForMutation.forEach((order) => {
      const isSupplierOrder = order.supplierOrders && order.supplierOrders.length > 0;

      if (isSupplierOrder) {
        order.supplierOrders.forEach((supplierOrder) => {
          const formattedSuppliersOrders = formatOrders(supplierOrder, order.number, order.createdDate, true);
          _ordersForExport.push(formattedSuppliersOrders);
        });
      } else {
        const formattedOrders = formatOrders(order, order.number, order.createdDate);
        _ordersForExport.push(formattedOrders);
      }
    });

    return _ordersForExport;
  }

  function formatOrders(
    order: OpusCustomerOrderType,
    orderNumber: string,
    createdDate: string,
    isSupplierOrder?: boolean,
  ): OpShortOrderType {
    const formattedPaymentType = t(`payment_methods.${order.inPayments[0]?.paymentMethod?.typeName}`);
    const supplierOrderActualTotal =
      order.updatedTotal?.amount && order.updatedTotal?.amount > 0 ? order.updatedTotal?.formattedAmount : "Pending";
    const supplierOrderEstimatedTotal = order.total?.formattedAmount;
    const contractNumbers =
      ((uniq(order.items?.map((item) => item.contractNumber))?.join(",") || "") as string) ||
      ((order.dynamicProperties?.find((prop) => prop.name === "ContractNumber")?.value || "") as string);
    const supplierEstShipping = order.shippingTotal?.formattedAmount;
    const supplierEstTax = order.taxTotal?.formattedAmount;
    const supplierActualShipping = order.updatedShippingTotal?.formattedAmount;
    const supplierActualTax = order.updatedTaxTotal?.formattedAmount;

    return {
      CustomerOrderNumber: orderNumber,
      SupplierOrderNumber: isSupplierOrder ? order.number : "-",
      SupplierName: isSupplierOrder ? order.vendor?.name : "-",
      CreatedDate: d(createdDate),
      CustomerName: order.customerName,
      PaymentType: formattedPaymentType,
      SupplierOrderStatus: order.status,
      ShippingTotal: supplierEstShipping || "-",
      TaxTotal: supplierEstTax || "-",
      SupplierOrderEstimatedTotal: supplierOrderEstimatedTotal,
      UpdatedShippingTotal: supplierActualShipping || "-",
      UpdatedTaxTotal: supplierActualTax || "-",
      SupplierOrderActualTotal: supplierOrderActualTotal,
      ContractNumbers: contractNumbers || "-",
    };
  }

  async function fetchOrdersForExport() {
    const ordersForPage = 100;

    let allOrders: OpusCustomerOrderConnection;
    exportingOrders.value = true;
    const filterExpression = getFilterExpression(keyword.value, appliedFilterData.value);
    try {
      allOrders = await getOrdersForExport({
        sort: sort.value.toString(),
        first: ordersForPage,
        after: String((exportPage.value - 1) * ordersForPage),
        filter: filterExpression,
        facet: STATUS_ORDERS_FACET_NAME,
        // OPUS
        isMainOrder: true,
        // !OPUS
      });
    } catch (e) {
      Logger.error(`${useUserOrders.name}.${fetchOrdersForExport.name}`, e);
      throw e;
    } finally {
      exportingOrders.value = false;
    }

    return allOrders;
  }
  // !OPUS

  return {
    sort,
    fetchOrders,
    loading: readonly(loading),
    orders: computed(() => orders.value),
    facets: computed(() =>
      facets.value
        ?.filter((f) => f.term !== "Split")
        .sort(
          (a, b) =>
            (facetOrdering.get(a.label.toLowerCase()) || Infinity) -
            (facetOrdering.get(b.label.toLowerCase()) || Infinity),
        )
        .reduce((acc, facet) => {
          if (facet.term === "Pending" || facet.term === "Processing") {
            const existingProcessing = acc.find((f) => f.term === "Processing");
            if (existingProcessing) {
              existingProcessing.count += facet.count;
            } else {
              acc.push({ ...facet, term: "Processing", label: "Processing" });
            }
          } else {
            acc.push(facet);
          }
          return acc;
        }, [] as FacetTermType[]),
    ),
    itemsPerPage,
    pages,
    page,
    keyword,
    // OPUS
    loadingMore,
    fetchOrdersForExport,
    exportingOrders: readonly(exportingOrders),
    prepareOrdersForExport,
    exportPage,
    fetchMoreOrders,
    // !OPUS
  };
}

function getFilterExpression(keyword: string, filterData: OrdersFilterDataType): string {
  let filterExpression = "";
  if (keyword) {
    filterExpression += `${keyword} `;
  }
  if (filterData.statuses.length) {
    const statuses = filterData.statuses.map((status) => `"${status}"`);
    filterExpression += `status:${statuses.join(",")} `;
  }
  // OPUS
  if (filterData.suppliers.length) {
    const suppliers = filterData.suppliers.map((supplier) => `"${supplier.id}"`);
    filterExpression += `supplierId:${suppliers.join(",")} `;
  }
  // !OPUS

  const startDateFilterValue = toStartDateFilterValue(filterData.startDate);
  const endDateFilterValue = toEndDateFilterValue(filterData.endDate);
  if (startDateFilterValue || endDateFilterValue) {
    let createdDateFilterValue = "";
    if (startDateFilterValue) {
      createdDateFilterValue += `"${startDateFilterValue}" `;
    }
    createdDateFilterValue += "TO";
    if (endDateFilterValue) {
      createdDateFilterValue += ` "${endDateFilterValue}"`;
    }
    filterExpression += `createddate:[${createdDateFilterValue}]`;
  }

  filterExpression = filterExpression.trim();

  return filterExpression;
}
