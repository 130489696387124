<template>
  <VcProductCard view-mode="list" class="flex flex-col items-start">
    <router-link :to="link" class="contents" @click="$emit('linkClick', $event)">
      <VcProductImage :img-src="product.imgSrc" :alt="product.name" size-suffix="sm" lazy />
    </router-link>

    <div class="flex flex-col justify-start space-y-[0.7rem] overflow-hidden">
      <VcProductTitle :lines-number="2" fix-height :to="link" :title="product.name" @click="$emit('linkClick', $event)">
        <span v-html-safe="replaceUnicode(product.name)" />
      </VcProductTitle>

      <!-- Product props -->
      <div id="sku-block" class="grid grid-cols-[auto_1fr] overflow-hidden text-xs leading-[1.063rem]">
        <span class="pr-3.5 font-bold">{{ $t("common.labels.sku") }}</span>
        <span class="truncate">{{ product.code }}</span>

        <!-- OPUS -->
        <!-- <VcProductPrice :actual-price="price?.actual" :has-variations="product.hasVariations" /> -->
        <!-- /OPUS -->
      </div>
    </div>
  </VcProductCard>
</template>

<script setup lang="ts">
import { computed } from "vue";
import { getProductRoute } from "@/core/utilities";
import { replaceUnicode } from "@/shared/opus";
import type { Product } from "@/core/api/graphql/types";
import type { RouteLocationRaw } from "vue-router";

interface IEmits {
  (eventName: "linkClick", globalEvent: PointerEvent): void;
}

interface IProps {
  product: Product;
}

defineEmits<IEmits>();
const props = defineProps<IProps>();

const price = computed(() => (props.product.hasVariations ? props.product.minVariationPrice : props.product.price));

const link = computed<RouteLocationRaw>(() => getProductRoute(props.product.id, props.product.slug));
</script>
