import { computed, readonly, ref, shallowRef } from "vue";
import { useI18n } from "vue-i18n";
import { useRoute } from "vue-router";
import {
  getSettingValues,
  opGetQuickConnectDetails,
  opGetQuickConnectHistory,
  opGetQuickConnectOrganizationHistory,
  opGetQCHistoryForExport,
  opGetQCOrganizationHistoryForExport,
  createQuoteRequests,
} from "@/core/api/graphql";
import { getSupplier } from "@/core/api/graphql/organization/queries/getSupplier";
import { useGoogleAnalytics, useOpus } from "@/core/composables";
import { AddressType, SortDirection } from "@/core/enums";
import { getSortingExpression, Logger, toEndDateFilterValue, toStartDateFilterValue } from "@/core/utilities";
import { useNotifications } from "@/shared/notification";
// eslint-disable-next-line import/no-cycle
import { opUseQuickConnectFilters } from "../..";
import type { OpQuickConnectExportType, QCHistoryFilterDataType } from "../types";
import type {
  QueryQuoteArgs,
  OpusQuoteType,
  QuoteAddressType,
  CreateQuoteRequestsResultType,
  InputCreateQuoteRequestsType,
  SupplierType,
  OpusQuoteConnection,
  OpusContactType,
} from "@/core/api/graphql/types";
import type { ISortInfo } from "@/core/types";
import type { Ref } from "vue";

export type SupplierQuickConnectInfoType = {
  location?: string;
  searchPhrase?: string;
  categoryId?: string;
  categoryName?: string;
  productCode?: string;
  productId?: string;
  productName?: string;
};

const DEFAULT_ITEMS_PER_PAGE = 10;
const loading = ref(false);
const sort: Ref<ISortInfo> = ref({
  column: "createdDate",
  direction: SortDirection.Descending,
});

const purposes = ref<string[]>([]);
const isAgencyRequest = ref(false);
const supplierQuickConnectInfo = ref<SupplierQuickConnectInfoType>({
  location: "",
});

export function opUseQuickConnect() {
  const { d, t } = useI18n();
  const route = useRoute();

  const { newQuickConnectEnabled } = useOpus();
  const { appliedFilterData } = opUseQuickConnectFilters();

  const ga = useGoogleAnalytics();
  const notifications = useNotifications();

  const loadingMore = ref(false);
  const itemsPerPage: Ref<number> = ref(DEFAULT_ITEMS_PER_PAGE);
  const pages: Ref<number> = ref(0);
  const page: Ref<number> = ref(1);
  const exportPage: Ref<number> = ref(1);
  const keyword: Ref<string> = ref("");
  const exportingQuickConnect = ref(false);

  const quickConnectData = ref<OpusQuoteType | undefined>();
  const quickConnectHistory: Ref<OpusQuoteType[]> = shallowRef<OpusQuoteType[]>([]);
  const shippingAddress = computed<QuoteAddressType | undefined>(() =>
    quickConnectData.value?.addresses?.find(
      (address: QuoteAddressType) => address.addressType === AddressType.Shipping,
    ),
  );

  async function fetchQuickConnectHistoryForExport() {
    const quickConnectsPerPage = 100;

    let allQuickConnects: OpusQuoteConnection;
    exportingQuickConnect.value = true;

    const sortingExpression = getSortingExpression(sort.value);
    const filterExpression = getFilterExpression(appliedFilterData.value);

    const requestData = {
      first: quickConnectsPerPage,
      after: String((exportPage.value - 1) * quickConnectsPerPage),
      sort: sortingExpression,
      keyword: keyword.value?.trim(),
      filter: filterExpression,
    };
    try {
      if (!isAgencyRequest.value) {
        allQuickConnects = await opGetQCHistoryForExport(requestData);
      } else {
        allQuickConnects = await opGetQCOrganizationHistoryForExport(requestData);
      }
    } catch (e) {
      Logger.error("opUseQuickConnect.fetchQuickConnectHistoryForExport", e);
      throw e;
    } finally {
      exportingQuickConnect.value = false;
    }

    return allQuickConnects;
  }

  async function fetchQuickConnectHistory(): Promise<void> {
    let connectResponse: OpusQuoteConnection;
    loading.value = true;
    const sortingExpression = getSortingExpression(sort.value);
    const filterExpression = getFilterExpression(appliedFilterData.value);

    const requestData = {
      first: itemsPerPage.value,
      after: String((page.value - 1) * itemsPerPage.value),
      sort: sortingExpression,
      keyword: keyword.value?.trim(),
      filter: filterExpression,
    };
    try {
      if (isAgencyRequest.value) {
        connectResponse = await opGetQuickConnectOrganizationHistory(requestData);
      } else {
        connectResponse = await opGetQuickConnectHistory(requestData);
      }

      quickConnectHistory.value = connectResponse.items ?? [];
      pages.value = Math.ceil((connectResponse.totalCount ?? 0) / itemsPerPage.value);
    } catch (e) {
      Logger.error("opUseQuickConnect.fetchQuickConnectHistory", e);
      throw e;
    } finally {
      loading.value = false;
    }
  }

  async function fetchSupplier(id: string) {
    loading.value = true;
    let supplier: SupplierType;
    try {
      supplier = await getSupplier(id);
    } catch (e) {
      Logger.error(`${opUseQuickConnect.name}.${fetchQuickConnectDetails.name}`, e);
      throw e;
    } finally {
      loading.value = false;
    }

    return supplier;
  }

  async function fetchQuickConnectDetails(payload: QueryQuoteArgs): Promise<void> {
    loading.value = true;

    try {
      quickConnectData.value = await opGetQuickConnectDetails(payload);
    } catch (e) {
      Logger.error(`${opUseQuickConnect.name}.${fetchQuickConnectDetails.name}`, e);
      throw e;
    } finally {
      loading.value = false;
    }
  }

  async function fetchSuppliersPurposes() {
    if (!purposes.value.length) {
      try {
        purposes.value = await getSettingValues({ name: "Opus.SupplierRequestPurposes" });
      } catch (e) {
        Logger.error(`${opUseQuickConnect.name}.${fetchSuppliersPurposes.name}`, e);
        throw e;
      }
    }
  }

  async function quickConnectWithSupplier(payload: InputCreateQuoteRequestsType) {
    let quickConnectResponse: CreateQuoteRequestsResultType;

    if (supplierQuickConnectInfo.value.location !== "Product") {
      delete supplierQuickConnectInfo.value.productCode;
      delete supplierQuickConnectInfo.value.productId;
      delete supplierQuickConnectInfo.value.productName;
    }

    payload.quoteReferralInfo = supplierQuickConnectInfo.value;

    try {
      loading.value = true;
      quickConnectResponse = await createQuoteRequests({ ...payload });

      ga.supplierQuickConnectFormSubmit(payload.supplierIds || [], payload.email);
      notifications.success({
        text: t("shared.catalog.supplier_request_modal.success_notification"),
        duration: 5000,
        single: true,
      });
    } catch (e) {
      notifications.error({
        text: t("shared.catalog.supplier_request_modal.error_notification"),
        duration: 10000,
        single: true,
      });
      Logger.error(`${opUseQuickConnect.name}.${quickConnectWithSupplier.name}`, e);
      throw e;
    } finally {
      loading.value = false;
    }
    return quickConnectResponse;
  }

  async function sendRequest(payload: InputCreateQuoteRequestsType) {
    // TODO: Remove after refactoring
    switch (route.name) {
      case "Matcher":
        supplierQuickConnectInfo.value.location = "Product";
        break;
      case "CategoriesAndSuppliersHome":
        supplierQuickConnectInfo.value.location = "Homepage";
        break;
      case "Suppliers":
        supplierQuickConnectInfo.value.location = "SuppliersList";
        break;

      case "Search":
        supplierQuickConnectInfo.value.location = "SearchResultPage";
        break;

      default:
        break;
    }

    if (supplierQuickConnectInfo.value.location !== "Product") {
      delete supplierQuickConnectInfo.value.productCode;
      delete supplierQuickConnectInfo.value.productId;
      delete supplierQuickConnectInfo.value.productName;
    }

    payload.quoteReferralInfo = supplierQuickConnectInfo.value;

    try {
      await createQuoteRequests({ ...payload });
      ga.supplierQuickConnectFormSubmit(payload.supplierIds || [], payload.email);
      notifications.success({
        text: t("shared.catalog.supplier_request_modal.success_notification"),
        duration: 5000,
        single: true,
      });
    } catch (e) {
      notifications.error({
        text: t("shared.catalog.supplier_request_modal.error_notification"),
        duration: 10000,
        single: true,
      });
      Logger.error(`${opUseQuickConnect.name}.${sendRequest.name}`, e);
      throw e;
    }
  }

  function setSupplierQuickConnectInfo(info: SupplierQuickConnectInfoType) {
    info.location ? setSupplierQuickConnectLocation(info.location) : null;
    info.searchPhrase ? (supplierQuickConnectInfo.value.searchPhrase = info.searchPhrase) : null;
    info.categoryId ? (supplierQuickConnectInfo.value.categoryId = info.categoryId) : null;
    info.categoryName ? (supplierQuickConnectInfo.value.categoryName = info.categoryName) : null;
    info.productCode ? (supplierQuickConnectInfo.value.productCode = info.productCode) : null;
    info.productId ? (supplierQuickConnectInfo.value.productId = info.productId) : null;
    info.productName ? (supplierQuickConnectInfo.value.productName = info.productName) : null;
  }

  function clearQuickConnectData(): void {
    quickConnectData.value = undefined;
  }

  function prepareQuickConnectDataForExport(quickConnectMutated: OpusQuoteType[]) {
    const _quickConnectForExport: OpQuickConnectExportType[] = [];
    quickConnectMutated.forEach((qc) => {
      const formattedForExportData = formatQuickConnect(qc);
      _quickConnectForExport.push(formattedForExportData);
    });

    return _quickConnectForExport;
  }

  function formatQuickConnect(qcHistory: OpusQuoteType): OpQuickConnectExportType {
    if (isAgencyRequest.value) {
      return {
        Id: qcHistory.number,
        Date: d(qcHistory.createdDate as Date),
        SupplierName: qcHistory.supplierName || "",
        BuyerName: qcHistory.customerName,
        SupplierCategory: qcHistory.supplier?.description || "",
        ContactPurpose: formattedPurposeTypeToExportView(qcHistory.purpose),
        Comment: qcHistory.comment,
      };
    } else {
      return {
        Id: qcHistory.number,
        Date: d(qcHistory.createdDate as Date),
        SupplierName: qcHistory.supplierName || "",
        SupplierCategory: qcHistory.supplier?.description || "",
        ContactPurpose: formattedPurposeTypeToExportView(qcHistory.purpose),
        Comment: qcHistory.comment,
      };
    }
  }

  return {
    itemsPerPage,
    pages,
    page,
    keyword,
    sort,
    isAgencyRequest,
    setSupplierQuickConnectInfo,
    sendRequest,
    fetchSupplier,
    fetchSuppliersPurposes,
    purposes,
    quickConnectWithSupplier,
    quickConnectHistory,
    quickConnectData: computed(() => quickConnectData.value),
    fetchQuickConnectHistoryForExport,
    prepareQuickConnectDataForExport,
    exportingQuickConnect: readonly(exportingQuickConnect),
    exportPage,
    shippingAddress,
    clearQuickConnectData,
    fetchQuickConnectDetails,
    fetchQuickConnectHistory,
    loading: readonly(loading),
    loadingMore: readonly(loadingMore),
    supplierQuickConnectInfo,
    newQuickConnectEnabled,
  };

  function getFilterExpression(filterData: QCHistoryFilterDataType): string {
    let filterExpression = "";

    if (filterData.purposes.length) {
      const qcPurposes = filterData.purposes.map((purpose: string) => {
        return `"${purpose}","${formattedPurposeType(purpose)}"`;
      });
      filterExpression += `purposes:${qcPurposes.join(",")} `;
    }

    if (filterData.suppliers.length) {
      const suppliersId = filterData.suppliers.map((supplier: SupplierType) => `"${supplier.id}"`);
      filterExpression += `supplierIds:${suppliersId.join(",")} `;
    }

    if (filterData.buyers?.length) {
      const customersId = filterData.buyers.map((buyer: OpusContactType) => `"${buyer.id}"`);
      filterExpression += `customerIds:${customersId.join(",")} `;
    }

    // if (isAgencyRequest.value) {
    //   filterExpression += `organizationId:"${organization.value?.id}"`;
    // }

    const startDateFilterValue = toStartDateFilterValue(filterData.startDate);
    const endDateFilterValue = toEndDateFilterValue(filterData.endDate);
    if (startDateFilterValue || endDateFilterValue) {
      let createdDateFilterValue = "";
      if (startDateFilterValue) {
        createdDateFilterValue += `"${startDateFilterValue}" `;
      }

      createdDateFilterValue += "TO";
      if (endDateFilterValue) {
        createdDateFilterValue += ` "${endDateFilterValue}"`;
      }

      filterExpression += ` createddate:[${createdDateFilterValue}]`;
    }

    filterExpression = filterExpression.trim();

    return filterExpression;
  }

  function setSupplierQuickConnectLocation(routeName: string) {
    switch (routeName) {
      case "Matcher":
        supplierQuickConnectInfo.value.location = "Product";
        break;
      case "CategoriesAndSuppliersHome":
        supplierQuickConnectInfo.value.location = "Homepage";
        break;
      case "Suppliers":
        supplierQuickConnectInfo.value.location = "SuppliersList";
        break;
      case "Search":
        supplierQuickConnectInfo.value.location = "SearchResultPage";
        break;
      case "Product":
        supplierQuickConnectInfo.value.location = "Product";
        break;
    }
  }

  function formattedPurposeTypeToExportView(purpose?: string) {
    switch (purpose) {
      case "services":
        return "Services";
      case "bulkQuote":
        return "Bulk Quote";
      case "productInquiry":
        return "Product Inquiry";
      case "salesSupport":
        return "Sales Support";
      default:
        return purpose;
    }
  }

  // Extended for support old and new quick connect
  function formattedPurposeType(purpose: string) {
    switch (purpose) {
      case "Service Inquiry":
        return "services";
      case "Bulk Quote":
        return "bulkQuote";
      case "Product Inquiry":
        return "productInquiry";
      case "Sales Support":
        return "salesSupport";
      default:
        return purpose;
    }
  }
}
