import { computed, readonly, ref, shallowRef } from "vue";
import { getOrdersForApproval } from "@/core/api/graphql/orders";
import { SortDirection } from "@/core/enums";
import { globals } from "@/core/globals";
import { getSortingExpression, Logger, toEndDateFilterValue, toStartDateFilterValue } from "@/core/utilities";
import { useUserOrdersFilter } from "@/shared/account/composables/useUserOrdersFilter";
import type { OpusContactType, OrderApprovalRequestType } from "@/core/api/graphql/types";
import type { ISortInfo } from "@/core/types";
import type { OrdersFilterDataType } from "@/shared/account/types";
import type { Ref } from "vue";

const DEFAULT_ITEMS_PER_PAGE = 10;
const notificationsCount: Ref<number> = ref(0);

export function useUserOrdersForApproval() {
  const { storeId } = globals;

  const { appliedFilterData } = useUserOrdersFilter();

  const orders: Ref<OrderApprovalRequestType[]> = shallowRef<OrderApprovalRequestType[]>([]);
  const loading: Ref<boolean> = ref(false);
  const itemsPerPage: Ref<number> = ref(DEFAULT_ITEMS_PER_PAGE);
  const pages: Ref<number> = ref(0);
  const page: Ref<number> = ref(1);
  const keyword: Ref<string> = ref("");

  // TODO: refine the sorting logic
  const sort: Ref<ISortInfo> = ref({
    column: "createdDate",
    direction: SortDirection.Descending,
  });

  async function loadOrders() {
    loading.value = true;

    const sortingExpression = getSortingExpression(sort.value);
    const filterExpression = getFilterExpression(keyword.value, appliedFilterData.value);

    try {
      const response = await getOrdersForApproval({
        sort: sortingExpression,
        first: itemsPerPage.value,
        after: String((page.value - 1) * itemsPerPage.value),
        keyword: keyword.value,
        filter: filterExpression,
        storeId,
      });
      orders.value = response.items ?? [];
      pages.value = Math.ceil((response.totalCount ?? 0) / itemsPerPage.value);
    } catch (e) {
      Logger.error("useUserOrdersForApproval.loadOrders", e);
      throw e;
    } finally {
      loading.value = false;
    }
  }

  async function updateNotificationsCount() {
    try {
      const response = await getOrdersForApproval({
        first: 0,
        filter: 'orderStatus:"Approval needed"',
        storeId,
      });
      notificationsCount.value = response.totalCount || 0;
    } catch (e) {
      Logger.error("useUserOrdersForApproval.updateNotificationsCount", e);
      throw e;
    }
  }

  return {
    sort,
    loadOrders,
    loading: readonly(loading),
    orders: computed(() => orders.value),
    itemsPerPage,
    pages,
    page,
    keyword,
    notificationsCount,
    updateNotificationsCount,
  };
}

function getFilterExpression(keyword: string, filterData: OrdersFilterDataType): string {
  let filterExpression = "";
  if (keyword) {
    filterExpression += `${keyword} `;
  }
  if (filterData.statuses.length) {
    const statuses = filterData.statuses.map((status) => `"${status}"`);
    filterExpression += `orderStatus:${statuses.join(",")} `;
  }
  if (filterData.approvalStatuses.length) {
    const approvalStatuses = filterData.approvalStatuses.map((approvalStatus) => `"${approvalStatus}"`);
    filterExpression += `status:${approvalStatuses.join(",")} `;
  }
  if (filterData.buyers?.length) {
    const customersId = filterData.buyers.map((buyer: OpusContactType) => `"${buyer.id}"`);
    filterExpression += `customerIds:${customersId.join(",")} `;
  }

  const startDateFilterValue = toStartDateFilterValue(filterData.startDate);
  const endDateFilterValue = toEndDateFilterValue(filterData.endDate);
  if (startDateFilterValue || endDateFilterValue) {
    let createdDateFilterValue = "";
    if (startDateFilterValue) {
      createdDateFilterValue += `"${startDateFilterValue}" `;
    }
    createdDateFilterValue += "TO";
    if (endDateFilterValue) {
      createdDateFilterValue += ` "${endDateFilterValue}"`;
    }
    filterExpression += `createddate:[${createdDateFilterValue}]`;
  }

  filterExpression = filterExpression.trim();

  return filterExpression;
}
