<template>
  <footer v-if="!isPrivateSector" aria-label="Footer">
    <!-- Top footer -->
    <div
      v-if="!compact && isAuthenticated && !user?.passwordExpired"
      class="hidden bg-[--footer-top-bg-color] text-[--footer-top-text-color] md:block print:!hidden"
    >
      <div class="container mx-auto grid grid-cols-2 gap-4 px-12 pb-8 pt-12 lg:grid-cols-4 xl:grid-cols-5">
        <!-- Logo column -->
        <div class="ml-1 hidden text-11 xl:block">
          <VcImage :src="secondaryLogoUrl" :alt="$context.storeName" class="mb-4 h-14" lazy />
          <address v-html-safe="$t('shared.layout.footer.owner_address')" class="mb-3 ml-1"></address>
          <a class="ml-1" :href="'mailto:' + $t('shared.layout.footer.owner_email')">{{
            $t("shared.layout.footer.owner_email")
          }}</a>
        </div>

        <!-- Column 1 -->
        <div>
          <div
            v-t="'shared.layout.footer.company_information_link_group'"
            class="mb-3 text-15 font-extrabold text-additional-50"
          />
          <div class="flex flex-col space-y-1">
            <!-- <OPUS> -->
            <a
              v-t="'shared.layout.footer.about_us_link'"
              target="_blank"
              href="/about-us"
              class="footer-link text-13 font-medium text-[color:var(--color-footer-top-link)] hover:text-[color:var(--color-footer-top-link-hover)]"
            />
            <a
              v-t="'shared.layout.footer.investor_relations_link'"
              target="_blank"
              href="https://www.omniapartners.com/"
              class="footer-link text-13 font-medium text-[color:var(--color-footer-top-link)] hover:text-[color:var(--color-footer-top-link-hover)]"
            />
            <!-- <FooterLink
              v-t="'shared.layout.footer.about_us_link'"
              to="https://www.omniapartners.com/who-we-are"
            ></FooterLink> -->
            <!-- <FooterLink v-t="'shared.layout.footer.supplier_overview_link'" to="/supplier-overview"></FooterLink>
            <FooterLink v-t="'shared.layout.footer.worldwide_link'" to="/worldwide"></FooterLink> -->
            <!-- </OPUS> -->
          </div>
        </div>

        <!-- Column 2 -->
        <div>
          <div
            v-t="'shared.layout.footer.account_information_link_group'"
            class="mb-3 text-15 font-extrabold text-additional-50"
          />
          <div
            class="footer-link flex cursor-pointer flex-col space-y-1 text-13 font-medium text-[color:var(--color-footer-top-link)] hover:text-[color:var(--color-footer-top-link-hover)]"
          >
            <router-link v-t="'shared.layout.footer.account_link'" :to="{ name: 'Profile' }"></router-link>
            <router-link v-t="'shared.layout.footer.orders_link'" :to="{ name: 'Orders' }"></router-link>
            <router-link v-t="'shared.layout.footer.cart_link'" :to="{ name: 'Cart' }"></router-link>
          </div>
        </div>

        <!-- Column 3 -->
        <div>
          <div
            v-t="'shared.layout.footer.online_resources_ling_group'"
            class="mb-3 text-15 font-extrabold text-additional-50"
          />
          <div
            class="footer-link flex cursor-pointer flex-col space-y-1 text-13 font-medium text-[color:var(--color-footer-top-link)] hover:text-[color:var(--color-footer-top-link-hover)]"
          >
            <router-link v-t="'shared.layout.footer.faq_link'" :to="{ name: 'FAQ' }" />
          </div>
        </div>

        <!-- Column 4 -->
        <div>
          <div
            v-t="'shared.layout.footer.customer_support_link_group'"
            class="mb-3 text-15 font-extrabold text-additional-50"
          />
          <div class="flex flex-col">
            <!-- OPUS -->
            <span
              v-t="'shared.layout.footer.contact_us_link'"
              class="footer-link cursor-pointer text-13 font-medium text-[color:var(--color-footer-top-link)] hover:text-[color:var(--color-footer-top-link-hover)]"
              @click="openContactUsModal()"
            />
            <!-- <FooterLink v-t="'shared.layout.footer.contact_us_link'" to="/contact-us"></FooterLink> -->
            <!-- !OPUS -->

            <!-- Social media block -->
            <div class="mt-3 flex flex-row">
              <a target="_blank" href="https://www.linkedin.com/company/omnia-partners">
                <img class="ml-0 mr-2 h-6" src="/static/icons/social/linkedin-icon.svg" loading="lazy" alt="linkedIn" />
              </a>
              <a target="_blank" href="https://www.youtube.com/@omniapartners">
                <img class="ml-0 mr-2 h-6" src="/static/icons/social/youtube-icon.svg" loading="lazy" alt="youtube" />
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- Bottom footer -->
    <div
      :class="{
        'bg-additional-50 text-neutral-500': hideTermsLinkForAnon,
        'bg-[color:var(--color-footer-bottom-bg)]  text-[--footer-bottom-text-color]': !hideTermsLinkForAnon,
      }"
      class="flex h-20 flex-col items-center justify-between px-4 py-3 text-xs font-semibold md:h-16 md:flex-row md:px-12 print:hidden print:flex-row print:bg-additional-50 print:px-0 print:text-additional-950"
    >
      <span>
        © <span class="mr-1">{{ new Date().getFullYear() }}</span> &#8226;
        <strong class="ml-1">{{ $t("shared.layout.footer.company_name") }}</strong
        >.
        {{ $t("shared.layout.footer.all_rights_reserved") }}
      </span>

      <router-link
        v-if="!hideTermsLinkForAnon"
        v-t="'shared.layout.footer.terms_of_use_link'"
        target="_blank"
        :to="{ name: 'TermsAndConditions', params: { sectionName: 'Terms&Conditions' } }"
      />
      <span>
        {{ $t("shared.layout.footer.powered_by") }}
        <a
          :class="{ 'text-primary': hideTermsLinkForAnon, 'text-[--footer-bottom-link-color]': !hideTermsLinkForAnon }"
          class="font-bold hover:text-[--footer-bottom-link-hover-color] print:text-additional-950"
          href="https://virtocommerce.com"
          target="_blank"
          rel="noopener noreferrer"
        >
          {{ $t("shared.layout.footer.by_virto") }}
        </a>
      </span>
    </div>
  </footer>
  <footer v-else aria-label="Footer">
    <!-- Top footer -->
    <div
      v-if="!compact && isAuthenticated && !user?.passwordExpired"
      class="hidden bg-[--footer-top-bg-color] text-[--footer-top-text-color] md:block print:!hidden"
    >
      <div class="container mx-auto grid grid-cols-2 gap-4 px-12 pb-8 pt-12 lg:grid-cols-4 xl:grid-cols-5">
        <!-- Logo column -->
        <div class="ml-1 hidden text-11 xl:block">
          <VcImage :src="secondaryLogoUrl" :alt="$context.storeName" class="mb-4 h-14" lazy />
          <address v-html-safe="$t('shared.layout.footer.owner_address')" class="mb-3 ml-1"></address>
          <a class="ml-1" :href="'mailto:' + $t('shared.layout.footer.owner_email')">{{
            $t("shared.layout.footer.owner_email")
          }}</a>
        </div>

        <!-- Column 1 -->
        <div>
          <div
            v-t="'shared.layout.footer.company_information_link_group'"
            class="mb-3 text-15 font-extrabold text-additional-50"
          />
          <div class="flex flex-col space-y-1">
            <!-- <OPUS> -->
            <a
              v-t="'shared.layout.footer.about_us_link'"
              target="_blank"
              href="/about-us"
              class="footer-link text-13 font-medium text-[color:var(--color-footer-top-link)] hover:text-[color:var(--color-footer-top-link-hover)]"
            />
            <a
              v-t="'shared.layout.footer.investor_relations_link'"
              target="_blank"
              href="https://www.omniapartners.com/"
              class="footer-link text-13 font-medium text-[color:var(--color-footer-top-link)] hover:text-[color:var(--color-footer-top-link-hover)]"
            />
            <!-- <FooterLink
              v-t="'shared.layout.footer.about_us_link'"
              to="https://www.omniapartners.com/who-we-are"
            ></FooterLink> -->
            <!-- <FooterLink v-t="'shared.layout.footer.supplier_overview_link'" to="/supplier-overview"></FooterLink>
            <FooterLink v-t="'shared.layout.footer.worldwide_link'" to="/worldwide"></FooterLink> -->
            <!-- </OPUS> -->
          </div>
        </div>

        <!-- Column 2 -->
        <div>
          <div
            v-t="'shared.layout.footer.account_information_link_group'"
            class="mb-3 text-15 font-extrabold text-additional-50"
          />
          <div
            class="footer-link flex cursor-pointer flex-col space-y-1 text-13 font-medium text-[color:var(--color-footer-top-link)] hover:text-[color:var(--color-footer-top-link-hover)]"
          >
            <router-link v-t="'shared.layout.footer.account_link'" :to="{ name: 'Profile' }"></router-link>
          </div>
        </div>

        <!-- Column 3 -->
        <div>
          <div
            v-t="'shared.layout.footer.online_resources_ling_group'"
            class="mb-3 text-15 font-extrabold text-additional-50"
          />
          <div
            class="footer-link flex cursor-pointer flex-col space-y-1 text-13 font-medium text-[color:var(--color-footer-top-link)] hover:text-[color:var(--color-footer-top-link-hover)]"
          >
            <router-link v-t="'shared.layout.footer.faq_link'" :to="{ name: 'FAQ' }" />
          </div>
        </div>

        <!-- Column 4 -->
        <div>
          <div
            v-t="'shared.layout.footer.customer_support_link_group'"
            class="mb-3 text-15 font-extrabold text-additional-50"
          />
          <div class="flex flex-col">
            <!-- OPUS -->
            <span
              v-t="'shared.layout.footer.contact_us_link'"
              class="footer-link cursor-pointer text-13 font-medium text-[color:var(--color-footer-top-link)] hover:text-[color:var(--color-footer-top-link-hover)]"
              @click="openContactUsModal()"
            />
            <!-- <FooterLink v-t="'shared.layout.footer.contact_us_link'" to="/contact-us"></FooterLink> -->
            <!-- !OPUS -->

            <!-- Social media block -->
            <div class="mt-3 flex flex-row">
              <a target="_blank" href="https://www.linkedin.com/company/omnia-partners">
                <img class="ml-0 mr-2 h-6" src="/static/icons/social/linkedin-icon.svg" loading="lazy" alt="linkedIn" />
              </a>
              <a target="_blank" href="https://www.youtube.com/@omniapartners">
                <img class="ml-0 mr-2 h-6" src="/static/icons/social/youtube-icon.svg" loading="lazy" alt="youtube" />
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- Bottom footer -->
    <div
      :class="{
        'bg-additional-50 text-neutral-500': hideTermsLinkForAnon,
        'bg-[color:var(--color-footer-bottom-bg)]  text-[--footer-bottom-text-color]': !hideTermsLinkForAnon,
      }"
      class="flex h-20 flex-col items-center justify-between px-4 py-3 text-xs font-semibold md:h-16 md:flex-row md:px-12 print:hidden print:flex-row print:bg-additional-50 print:px-0 print:text-additional-950"
    >
      <span>
        © <span class="mr-1">{{ new Date().getFullYear() }}</span> &#8226;
        <strong class="ml-1">{{ $t("shared.layout.footer.company_name") }}</strong
        >.
        {{ $t("shared.layout.footer.all_rights_reserved") }}
      </span>

      <router-link
        v-if="!hideTermsLinkForAnon"
        v-t="'shared.layout.footer.terms_of_use_link'"
        target="_blank"
        :to="{ name: 'TermsAndConditions', params: { sectionName: 'Terms&Conditions' } }"
      />
      <span>
        {{ $t("shared.layout.footer.powered_by") }}
        <a
          :class="{ 'text-primary': hideTermsLinkForAnon, 'text-[--footer-bottom-link-color]': !hideTermsLinkForAnon }"
          class="font-bold hover:text-[--footer-bottom-link-hover-color] print:text-additional-950"
          href="https://virtocommerce.com"
          target="_blank"
          rel="noopener noreferrer"
        >
          {{ $t("shared.layout.footer.by_virto") }}
        </a>
        for private sector
      </span>
    </div>
  </footer>
</template>

<script setup lang="ts">
import { computed } from "vue";
import { useRoute } from "vue-router";
import { useNavigations, useThemeContext, useWhiteLabeling } from "@/core/composables";
import { useUser } from "@/shared/account";
import { useModal } from "@/shared/modal";
import { OpContactUsDialog } from "@/shared/opus/common/components";
import pkg from "../../../../../package.json";
import FooterLink from "./_internal/footer-link.vue";
import FooterLinks from "./_internal/footer-links.vue";

interface IProps {
  compact?: boolean;
}

defineProps<IProps>();

const { themeContext } = useThemeContext();

const { openModal, closeModal } = useModal();
const { secondaryLogoUrl, footerLinks: whiteLabelingFooterLinks } = useWhiteLabeling();
const { footerLinks } = useNavigations();

const { version } = pkg;

//OPUS
const { isAuthenticated, user, isPrivateSector } = useUser();
const route = useRoute();

const anonymousRoutes = [
  "SignIn",
  "SignUp",
  "AboutUs",
  "ForgotPassword",
  "Invitation",
  "RegistrationInvitation",
  "OpWelcome",
  "OrderConfirm",
  "OrderCancel",
  "ResetPassword",
];
const routeName = route.name as string;
const hideTermsLinkForAnon = computed(
  () => (user.value.userName === "Anonymous" && anonymousRoutes.includes(routeName)) || routeName === "TemporaryPass",
);

function openContactUsModal() {
  openModal({
    component: OpContactUsDialog,
    props: {
      onResult() {
        closeModal();
      },
    },
  });
}
//!OPUS
</script>
