<template>
  <div ref="searchBarElement" class="relative flex grow items-stretch">
    <VcInput
      v-model="searchPhrase"
      :disabled="tabAccessabilityIndex == -1"
      :tabindex="tabAccessabilityIndex"
      :maxlength="MAX_LENGTH"
      class="w-full"
      :placeholder="$t('shared.layout.search_bar.enter_keyword_placeholder')"
      @keyup.enter="goToSearchResultsPage"
      @keyup.esc="hideSearchDropdown"
      @input="onSearchPhraseChanged"
    >
      <template #append>
        <button
          v-if="searchPhrase"
          :tabindex="tabAccessabilityIndex"
          :disabled="tabAccessabilityIndex == -1"
          aria-label="remove keyword"
          type="button"
          class="flex h-full items-center px-3"
          @click="reset"
        >
          <VcIcon name="delete-2" size="xs" class="fill-primary" />
        </button>

        <VcButton
          :aria-label="$t('shared.layout.search_bar.search_button')"
          :tabindex="tabAccessabilityIndex"
          :disabled="tabAccessabilityIndex == -1"
          title="search"
          icon="search"
          @click="goToSearchResultsPage"
        />
      </template>
    </VcInput>

    <!-- Dropdown -->
    <transition name="slide-fade-top">
      <div
        v-if="searchDropdownVisible"
        class="absolute left-1/2 top-[3.45rem] z-20 flex w-full min-w-[640px] max-w-[100vw] -translate-x-1/2 flex-col gap-1 overflow-y-auto rounded bg-additional-50 shadow-lg"
        :style="searchDropdownStyle"
      >
        <!-- Results -->
        <!-- v-if="categories.length || products.length || suppliers.length" -->
        <template v-if="isExistResults">
          <!-- Suggestions -->
          <section v-if="suggestions.length">
            <header class="bg-neutral-100 px-5 py-2 text-xs text-neutral">
              {{ $t("shared.layout.search_bar.suggestions_label") }}
            </header>

            <div class="flex gap-5 px-5 pb-3 pt-2.5 text-sm">
              <ul class="min-w-0">
                <li v-for="suggestion in suggestions" :key="suggestion.text">
                  <router-link
                    :to="getSearchRoute(suggestion.text)"
                    class="flex items-center gap-2 py-1"
                    @click="hideSearchDropdown"
                  >
                    <VcIcon name="search-circle" size="xs" class="shrink-0 fill-neutral-300" />
                    <span v-html-safe="suggestion.label" class="truncate" />
                  </router-link>
                </li>
              </ul>
            </div>
          </section>

          <!-- Pages -->
          <section v-if="pages.length">
            <header class="bg-neutral-100 px-5 py-2 text-xs text-neutral">
              {{ $t("shared.layout.search_bar.pages_label") }}
            </header>

            <div class="flex gap-5 px-5 pb-3 pt-2.5 text-sm">
              <ul>
                <li v-for="(page, index) in pages" :key="index">
                  <router-link :to="page.permalink!" class="block py-1" @click="hideSearchDropdown">
                    <span v-html-safe="page.name" />
                  </router-link>
                </li>
              </ul>
            </div>
          </section>

          <!-- OPUS -->
          <!-- Suppliers -->
          <section v-if="suppliers.length" class="pb-4">
            <header class="bg-neutral-100 px-5 py-2 text-xs text-neutral-500">
              {{ $t("shared.layout.search_bar.suppliers_label") }}
            </header>
            <div class="flex gap-5 px-5 pb-3 pt-2.5 text-sm">
              <ul v-for="(column, index) in suppliersColumns" :key="index" class="">
                <li v-for="supplier in column" :key="supplier.name" class="transition-colors hover:bg-neutral-100">
                  <router-link
                    :to="supplierRoutes[supplier.id]"
                    class="block py-1"
                    @click="
                      hideSearchDropdown();
                      setSupplierQuickConnectInfo({ searchPhrase: searchPhrase });
                    "
                  >
                    <span v-html-safe="supplier.name" />
                  </router-link>
                </li>
              </ul>
            </div>
            <!-- Actions -->
            <section v-if="suppliersTotal > 20" class="bottom-0 flex justify-end bg-additional-50 px-5">
              <VcButton
                :to="{ name: 'Suppliers', query: { [QueryParamName.SearchPhrase]: searchPhrase } }"
                size="sm"
                @click="hideSearchDropdown()"
              >
                {{ $t("common.buttons.show_all") }}
                <VcIcon size="xs" name="chevron-right" class="ml-2" />
              </VcButton>
            </section>
          </section>
          <!-- !OPUS -->

          <!-- Categories -->
          <section v-if="categories.length">
            <header class="bg-neutral-100 px-5 py-2 text-xs text-neutral">
              {{ $t("shared.layout.search_bar.categories_label") }}
            </header>

            <div class="flex gap-5 px-5 pb-3 pt-2.5 text-sm">
              <ul v-for="(column, index) in categoriesColumns" :key="index">
                <li v-for="category in column" :key="category.name" class="transition-colors hover:bg-neutral-100">
                  <router-link
                    :to="categoriesRoutes[category.id]"
                    class="block py-1"
                    @click="
                      hideSearchDropdown();
                      setSupplierQuickConnectInfo({ searchPhrase: searchPhrase });
                    "
                  >
                    <span v-html-safe="category.name" />
                  </router-link>
                </li>
              </ul>
            </div>
          </section>

          <!-- Products -->
          <section v-if="products.length" class="pb-4">
            <header class="bg-neutral-100 px-5 py-2 text-xs text-neutral">
              {{ $t("shared.layout.search_bar.products_label") }}
            </header>

            <div class="grid grid-cols-2 gap-y-0.5 p-3">
              <SearchBarProductCard
                v-for="product in products"
                :key="product.id"
                :product="product"
                @link-click="
                  setAnalyticsData(product);
                  hideSearchDropdown();
                "
              />
            </div>
          </section>

          <!-- Actions -->
          <section
            v-if="total"
            class="sticky bottom-0 mt-0.5 flex justify-end border-t border-neutral-100 bg-additional-50 px-5 py-3"
          >
            <VcButton
              :to="{ name: 'Search', query: { [QueryParamName.SearchPhrase]: searchPhrase } }"
              size="sm"
              @click="
                hideSearchDropdown();
                sendGaSearchEvent('Search');
                setSupplierQuickConnectInfo({ searchPhrase: searchPhrase });
              "
            >
              {{ $t("shared.layout.search_bar.view_all_results_button", { total }) }}
            </VcButton>
          </section>
        </template>

        <!-- Not found -->
        <div v-else-if="!loading" class="my-16 text-center">
          <VcIcon name="search-not-found" class="mr-5 inline-block !h-12 !w-12 fill-primary" />

          <i18n-t class="inline-block" keypath="shared.layout.search_bar.no_results" tag="p">
            <template #keyword>
              <strong>{{ trimmedSearchPhrase }}</strong>
            </template>
          </i18n-t>
        </div>
      </div>
    </transition>
  </div>
</template>

<script setup lang="ts">
import { useDebounceFn, whenever, useElementBounding, onClickOutside } from "@vueuse/core";
import { computed, inject, ref, watchEffect, unref } from "vue";
import { useRouter } from "vue-router";
import {
  useCategoriesRoutes,
  useAnalytics,
  useRouteQueryParam,
  useThemeContext,
  useGoogleAnalytics,
} from "@/core/composables";
import { useModuleSettings } from "@/core/composables/useModuleSettings";
import { DEFAULT_PAGE_SIZE } from "@/core/constants";
import { MODULE_XAPI_KEYS } from "@/core/constants/modules";
import { QueryParamName } from "@/core/enums";
import { globals } from "@/core/globals";
import { configInjectionKey } from "@/core/injection-keys";
import {
  getFilterExpressionForCategoryByParentId,
  getFilterExpressionForCategorySubtree,
  getFilterExpressionFromFacets,
} from "@/core/utilities";
import { useSuppliers } from "@/shared/opus/categories-and-suppliers";
import { opUseQuickConnect } from "@/shared/opus/quick-connect";
import { useSearchBar } from "../../composables";
import SearchBarProductCard from "./_internal/search-bar-product-card.vue";
import type { GetSearchResultsParamsType } from "@/core/api/graphql/catalog";
import type { Category, Product, SupplierType } from "@/core/api/graphql/types";
import type { StyleValue } from "vue";
import type { RouteLocationRaw } from "vue-router";

const props = defineProps<IProps>();

const searchBarElement = ref<HTMLElement | null>(null);

interface IProps {
  tabAccessabilityIndex?: number | undefined;
}
// Number of categories column items in dropdown list
const CATEGORIES_ITEMS_PER_COLUMN = 4;

const SEARCH_BAR_DEBOUNCE_TIME = 300;

const config = inject(configInjectionKey);

const MAX_LENGTH = config?.search_max_chars || 999;
const MIN_LENGTH = config?.search_min_chars || 0;

const {
  total,
  loading,
  pages,
  products,
  suggestions,
  categories,
  suppliers,
  searchBarVisible,
  searchDropdownVisible,
  suppliersTotal,
  hideSearchDropdown,
  showSearchDropdown,
  searchResults,
} = useSearchBar();

const ga = useGoogleAnalytics();
const { analytics } = useAnalytics();
const router = useRouter();
const { themeContext } = useThemeContext();
const { getFacetsQueryForSearchProducts } = useSuppliers();

const searchPhraseInUrl = useRouteQueryParam<string>(QueryParamName.SearchPhrase);
const categoriesRoutes = useCategoriesRoutes(categories);
//OPUS-BEGIN
const suppliersColumns = computed<Array<SupplierType[]>>(() => getTableFromRows(suppliers.value));
const supplierRoutes = computed(() =>
  unref(suppliers).reduce<Record<string, RouteLocationRaw>>((result, supplier) => {
    result[supplier.id] = getSupplierRoute(supplier);
    return result;
  }, {}),
);
const getSupplierRoute = (supplier: SupplierType): RouteLocationRaw => {
  const query: Record<string, string | undefined> = {};

  if (supplier.isConnected && supplier.isOrderAllowed) {
    if (trimmedSearchPhrase.value) {
      query[QueryParamName.SearchPhrase] = trimmedSearchPhrase.value;
    }

    query[QueryParamName.Facets] = getFacetsQueryForSearchProducts(supplier.outerId, false);

    return {
      name: "SearchProducts",
      query,
    };
  } else {
    //fetchSuppliers({ supplierIds: [supplier.id] });

    return {
      name: "Suppliers",
      query: {
        supplierId: supplier.id,
      },
    };
  }
};

const { setSupplierQuickConnectInfo } = opUseQuickConnect();
//OPUS-END

const searchPhrase = ref("");
const trimmedSearchPhrase = computed(() => {
  return searchPhrase.value.trim();
});

const isApplied = computed<boolean>(() => searchPhraseInUrl.value === trimmedSearchPhrase.value);

// eslint-disable-next-line @typescript-eslint/no-unsafe-return
const categoriesColumns = computed<Array<Category[]>>(() => getTableFromRows(categories.value));

const { bottom } = useElementBounding(searchBarElement);

const searchDropdownStyle = computed<StyleValue | undefined>(() => {
  return { maxHeight: bottom.value ? `calc(100vh - ${bottom.value + 40}px)` : "auto" };
});

onClickOutside(searchBarElement, () => hideSearchDropdown());
// eslint-disable-next-line @typescript-eslint/no-explicit-any
const getTableFromRows = (rows: Array<any>) => {
  const columnsCount: number = Math.ceil(rows.length / CATEGORIES_ITEMS_PER_COLUMN);
  return Array.from({ length: columnsCount }).map((_, index) => {
    const column: number = index + 1;
    // eslint-disable-next-line @typescript-eslint/no-unsafe-return
    return rows.slice((column - 1) * CATEGORIES_ITEMS_PER_COLUMN, column * CATEGORIES_ITEMS_PER_COLUMN);
  });
};

const isExistResults = computed(
  () =>
    categories.value.length ||
    products.value.length ||
    suggestions.value.length ||
    pages.value.length ||
    suppliers.value.length,
);

const { getSettingValue } = useModuleSettings(MODULE_XAPI_KEYS.MODULE_ID);

async function searchAndShowDropdownResults(): Promise<void> {
  const COLUMNS = 5;
  const { catalogId, currencyCode } = globals;
  const { search_product_phrase_suggestions_enabled, search_static_content_suggestions_enabled } =
    themeContext.value.settings;

  hideSearchDropdown();

  if (trimmedSearchPhrase.value.length > MAX_LENGTH || trimmedSearchPhrase.value.length < MIN_LENGTH) {
    return;
  }

  const { zero_price_product_enabled } = themeContext.value.settings;
  const catalog_empty_categories_enabled = getSettingValue(MODULE_XAPI_KEYS.CATALOG_EMPTY_CATEGORIES_ENABLED);

  const filterExpression = catalog_empty_categories_enabled
    ? undefined
    : [
        // getFilterExpressionForCategorySubtree({ catalogId }),
        // getFilterExpressionForZeroPrice(!!zero_price_product_enabled, currencyCode),
      ]
        .filter(Boolean)
        .join(" ");

  const params: GetSearchResultsParamsType = {
    keyword: trimmedSearchPhrase.value,
    filter: filterExpression,
    categories: {
      itemsPerPage: CATEGORIES_ITEMS_PER_COLUMN * COLUMNS,
      // OPUS
      // categoriesFilter: getFilterExpressionForCategoryByParentId(catalogId),
      // !OPUS
    },
    products: {
      itemsPerPage: 6,
    },
    suppliers: {
      isActive: true,
      itemsPerPage: CATEGORIES_ITEMS_PER_COLUMN * COLUMNS,
    },
  };

  if (search_product_phrase_suggestions_enabled) {
    params.productSuggestions = { suggestionsSize: 4 };
  }

  if (search_static_content_suggestions_enabled) {
    params.pages = { itemsPerPage: DEFAULT_PAGE_SIZE };
  }

  await searchResults(params);

  if (!isApplied.value) {
    sendGaSearchEvent("Suggestions");
    showSearchDropdown();
    // OPUS
    ga.viewSearchResults(
      trimmedSearchPhrase.value,
      products.value,
      total.value,
      suppliers.value,
      suppliersTotal.value,
      "Search bar suggestions",
      {
        categories:
          categories.value
            .map((c) => c.name)
            .join(", ")
            .trim() || [],
        categories_count: categories.value.length,
      },
    );
    // !OPUS
  }

  /**
   * Send Google Analytics event for products.
   */
  // OPUS
  // if (products.value.length) {
  //   analytics("viewItemList", products.value, {
  //     item_list_name: `Search phrase "${trimmedSearchPhrase.value}"`,
  //   });
  // }
  // !OPUS
}

// OPUS
function sendGaSearchEvent(source: string) {
  ga.search(trimmedSearchPhrase.value, source);
}

function setAnalyticsData(product: Product) {
  localStorage.setItem("search_source", "Search suggestions");
  ga.selectItem(product, { search_term: trimmedSearchPhrase.value, source: "Search suggestions" });
  setSupplierQuickConnectInfo({ searchPhrase: searchPhrase.value });
}
// !OPUS

function getSearchRoute(phrase: string): RouteLocationRaw {
  return {
    name: "Search",
    query: {
      [QueryParamName.SearchPhrase]: phrase,
    },
  };
}

function goToSearchResultsPage() {
  if (trimmedSearchPhrase.value) {
    hideSearchDropdown();
    // OPUS
    sendGaSearchEvent("Search");
    setSupplierQuickConnectInfo({ searchPhrase: searchPhrase.value });
    // !OPUS
    void router.push(getSearchRoute(trimmedSearchPhrase.value));
    // analytics("search", trimmedSearchPhrase.value, products.value, total.value);
  }
}

function reset() {
  searchPhrase.value = "";
  hideSearchDropdown();
}

const searchProductsDebounced = useDebounceFn(() => {
  if (!isApplied.value) {
    void searchAndShowDropdownResults();
  }
}, SEARCH_BAR_DEBOUNCE_TIME);

function onSearchPhraseChanged() {
  hideSearchDropdown();
  void searchProductsDebounced();
}

watchEffect(() => (searchPhrase.value = searchPhraseInUrl.value ?? ""));
whenever(searchBarVisible, () => (searchPhrase.value = searchPhraseInUrl.value ?? ""), { immediate: true });
</script>
